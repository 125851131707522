import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import CountUp from "react-countup"
import Img from "gatsby-image"

const Container = styled.section`
  max-width: 130rem;
  margin: 6rem auto;
  padding: 6rem 1.5rem 4rem;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #202020;
  z-index: -2;
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78b526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  color: #fafafa;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
  padding: 3rem;
  @media only screen and (max-width: 75em) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 37.5em) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  text-align: center;
  z-index: 10;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #fafafa;
  z-index: 10;
  @media only screen and (max-width: 32.5em) {
    font-size: 2rem;
  }
`

const Num = styled.span`
  font-size: 5rem;
  color: #fafafa;
  z-index: 10;
  &::after {
    content: "+";
  }
`

const Stats = ({ data: { title, subtitle, image } }) => {
  const [total, setTotal] = useState(3000000)

  const [start, setStart] = useState(false)
  function handleScroll(entries) {
    if (entries[0].isIntersecting) {
      setStart(true)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && "IntersectionObserver" in window) {
      const observer = new IntersectionObserver(handleScroll, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      })
      if (document.querySelector("#countStart")) {
        observer.observe(document.querySelector("#countStart"))
      }
    } else {
      setStart(true)
    }

    getTotalContainers()
  }, [])

  const getTotalContainers = async () => {
    try {
      const res = await fetch(
        "https://app.re-collect.com.au/api/getTotalContainersCount",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      const json = await res.json()
      setTotal(json.numberOfContainers)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Wrapper>
      <Image>
        {image.childImageSharp ? (
          <StyledImg fluid={image.childImageSharp.fluid} />
        ) : null}
      </Image>
      <Container>
        <Primary>{title}</Primary>
        <Subtitle id="countStart">{subtitle}</Subtitle>
        <Content>
          <Card>
            <Num>
              {start ? (
                <CountUp separator="," end={parseInt(total)} duration={3} />
              ) : (
                0
              )}
            </Num>
            <Secondary>Containers Recycled</Secondary>
          </Card>
          <Card>
            <Num>
              {start ? (
                <CountUp
                  separator=","
                  end={Math.round(total * 0.0512)}
                  duration={3}
                />
              ) : (
                0
              )}
            </Num>
            <Secondary>kg Spared from the Landfill</Secondary>
          </Card>
          <Card>
            <Num>
              {start ? (
                <CountUp
                  separator=","
                  end={Math.round(total * 0.0973)}
                  duration={3}
                />
              ) : (
                0
              )}
            </Num>
            <Secondary>kg of Greenhouse Gas (GHG) Saved</Secondary>
          </Card>
          <Card>
            <Num>
              {start ? (
                <CountUp
                  separator=","
                  end={Math.round(total * 0.333)}
                  duration={3}
                />
              ) : (
                0
              )}
            </Num>
            <Secondary>kWh of Energy Saved</Secondary>
          </Card>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Stats
