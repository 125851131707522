import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Apple from "../../static/img/svg/apple.svg"
import Google from "../../static/img/svg/google.svg"
import SkipTheDepotLogo from "../../static/img/svg/skipTheDepot-small.svg"
import HeroSvg from "./HeroSvg"
import Button from "./Button"

const Container = styled.section`
  position: relative;
  overflow: hidden;
  background: url(/img/svg/pattern.svg) center center/15%,
    linear-gradient(to bottom, #383838, #444, #454545);
  padding: 10rem 3rem 20rem;
  @media only screen and (max-width: 56.25em) {
    background: url(/img/svg/pattern.svg) center center/25%,
      linear-gradient(to bottom, #383838, #444, #454545);
  }
  @media only screen and (max-width: 52em) {
    padding: 3rem 1.5rem;
  }
  @media only screen and (max-width: 40em) {
    background: url(/img/svg/pattern.svg) center center/45%,
      linear-gradient(to bottom, #383838, #444, #454545);
  }
  @media only screen and (max-width: 32.5em) {
    background: url(/img/svg/pattern.svg) center center/65%,
      linear-gradient(to bottom, #383838, #444, #454545);
  }
`

const Svg = styled.svg`
  width: 101%;
  position: absolute;
  bottom: -1px;
  left: 0;
`

const Branding = styled.div`
  color: #fafafa;
  z-index: 2;
  padding-top: 5rem;
  @media only screen and (max-width: 52em) {
    text-align: center;
    padding: 0;
  }
`

const Primary = styled.h1`
  font-size: 4.5rem;
  line-height: 1.1;
  padding: 1rem 0;
  @media only screen and (max-width: 32.5em) {
    font-size: 5rem;
  }
  // span {
  //   display: block;
  //   font-weight: 400;
  //   font-size: 2rem;
  // }
  span.yellow {
    color: #ffcc33;
  }
  span.blue {
    color: #35abe2;
  }
`

const Secondary = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  padding: 2rem 0;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 130rem;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  @media only screen and (max-width: 52em) {
    grid-template-columns: 1fr;
    padding: 12rem 0;
  }
`

const SvgWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const StyledHeroSvg = styled(HeroSvg)`
  position: absolute;
  top: 10rem;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 40rem;
  z-index: 3;
  @media only screen and (max-width: 68.75em) {
    top: 0;
  }
`

const Cta = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.5rem 0;
  span {
    line-height: 1.3;
  }
  @media only screen and (max-width: 52em) {
    justify-content: center;
  }
  @media only screen and (max-width: 32.5em) {
    padding-bottom: 3rem;
  }
`

const Links = styled.div`
  padding: 1rem 0;
  @media only screen and (max-width: 32.5em) {
    text-align: center;
  }
`

const InternalLink = styled(Link)`
  color: #fafafa;
  text-decoration: none;
  position: relative;
  padding-bottom: 0.3rem;
  margin-right: 1.5rem;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #fafafa;
    bottom: 0;
    left: 0;
  }
  &:hover {
    &::after {
      height: 2px;
    }
  }
`

const StyledButton = styled(Button)`
  font-size: 1.5rem;
  margin: 1rem 2rem 1rem 0;
  padding-left: 1rem;
  border: 1px solid #202020;
  background-color: #202020;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #101010;
    border: 1px solid #101010;
  }
`

const Icon = styled.span`
  display: block;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
`

const Hero = ({ title, subtitle }) => {
  const svgStyles = { fill: "#fafafa", height: "100%" }
  return (
    <Container>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0.148 0.121 399.909 187.745"
      >
        <path
          d="M.317 160.263c.174.059 54.355 21.058 96.093 26.292 42.417 5.32 74.182-8.899 103.778-40.255 35.022-37.104 58.704-34.934 99.269-28.618 37.319 5.811 100.402 37.33 100.324 39.741C399.76 158.082 400 0 400 0H0"
          fill="#fafafa"
          transform="rotate(-180 200 146.629)"
        />
      </Svg>
      <Content>
        <Branding>
          <Primary>
            GET <span className="yellow">PAID</span> TO SAVE THE{" "}
            <span className="blue">PLANET</span>
          </Primary>
          <Secondary>{subtitle}</Secondary>
          <Cta>
            <a
              href="https://apps.apple.com/au/app/return-it-collect/id1474675831"
              target="__blank"
              style={{ textDecoration: "none" }}
            >
              <StyledButton>
                <Icon>
                  <Apple style={svgStyles} />
                </Icon>
                Download on
                <br />
                App Store
              </StyledButton>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=au.com.returnit"
              target="__blank"
              style={{ textDecoration: "none" }}
            >
              <StyledButton>
                <Icon>
                  <Google style={svgStyles} />
                </Icon>
                Get it on <br />
                Google Play
              </StyledButton>
            </a>
            <a
              href="https://app.re-collect.com.au"
              target="__blank"
              style={{ textDecoration: "none" }}
            >
              <StyledButton>
                <Icon>
                  <SkipTheDepotLogo style={svgStyles} />
                </Icon>
                Old-school <br />
                Web Login
              </StyledButton>
            </a>
          </Cta>
          <Links>
            <InternalLink to="/fundraising">
              Learn more about fundraising
            </InternalLink>
          </Links>
        </Branding>
        <SvgWrapper>
          <StyledHeroSvg />
        </SvgWrapper>
      </Content>
    </Container>
  )
}
export default Hero
