import React from "react"
import styled from "@emotion/styled"

import Phone from "./Phone"
import Cta from "./Cta"

const Wrapper = styled.section`
  background: #efefef;
  position: relative;
  margin: 6rem auto;
  svg {
    width: 100%;
    position: absolute;
    left: 0;
    fill: #fafafa;
    z-index: 1;
    :nth-of-type(1) {
      top: -1px;
    }
    :nth-of-type(2) {
      bottom: -1px;
    }
  }
`

const Container = styled.div`
  padding: 6rem 1.5rem 9rem;
  max-width: 130rem;
  margin: 0 auto;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78B526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.6;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`
const Content = styled.div`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: repeat(3, max-content);
  text-align: center;
  div {
    z-index: 10;
  }
  @media only screen and (max-width: 52em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr repeat(3, min-content);
    grid-row-gap: 3rem;
  }
  @media only screen and (max-width: 32.5em) {
    grid-template-columns: 1fr;
  }
`

const Device = styled.div`
  grid-column: 2/ 3;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 52em) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }
`
const Details = styled.div`
  padding: 0 1.5rem;
`

const Svg = styled.img`
  height: 7rem;
  width: 7rem;
`

const Title = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
`

const Body = styled.p`
  padding: 1.5rem 0;
  font-weight: 400;
  color: #505050;
`

const Benefits = ({ data: { title, subtitle, data } }) => (
  <Wrapper>
    <svg viewBox="0 0 1440 43">
      <path
        d="M1650.93 19.168L1440-76.58H0v98.79C120 8.35 240 1.42 360 1.42s450.93 3.888 570.93 17.748c88.328 8.794 154.574 14.333 198.738 16.618a3120.558 3120.558 0 00161.262 4.172c120 0 240-6.93 360-20.79z"
        id="path1052"
      />
    </svg>
    <svg viewBox="0 0 1440 45">
      <path d="M1440 23.28v98.79H0V23.28c120 13.86 240 20.79 360 20.79s240-6.93 360-20.79c88.328-8.793 154.574-14.333 198.738-16.618A3120.56 3120.56 0 011080 2.491c120 0 240 6.93 360 20.79z" />
    </svg>
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Content>
        <Device>
          <Phone />
        </Device>
        {data &&
          data.map(({ title, body, image }, count) => (
            <Details key={count}>
              <Svg
                src={image.relativePath ? `/img/${image.relativePath}` : image}
                alt={`Benefits image ${count}`}
                loading="lazy"
              />
              <Title>{title}</Title>
              <Body>{body}</Body>
            </Details>
          ))}
      </Content>
      <Cta href="https://app.re-collect.com.au/register">Book a free pickup</Cta>
    </Container>
  </Wrapper>
)

export default Benefits
