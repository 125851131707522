import React from "react"
import styled from "@emotion/styled"

import Img from "gatsby-image"
import Cta from "./Cta"

const Container = styled.div`
  max-width: 130rem;
  margin: 3rem auto;
  position: relative;
  z-index: 2;
`

const Brand = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  overflow: hidden;
  align-items: center;
  padding: 3rem;
  flex-wrap: wrap;
  ${Container}:hover & {
    filter: blur(10px);
    transform: scale(0.95);
  }
`

const Body = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  visibility: hidden;
  ${Container}:hover & {
    visibility: visible;
    opacity: 1;
  }
  @media only screen and (max-width: 32.5em) {
    visibility: visible;
    opacity: 1;
    top: 75%;
  }
`

const Image = styled(Img)`
  filter: grayscale(100%);
  margin: 0 2rem;
  object-fit: contain;
  flex: 0 0 auto;
  @media only screen and (max-width: 32.5em) {
    width: 10rem;
  }
`

const Partners = ({ images, cta }) => (
  <Container>
    <Brand>
      {images &&
        images
          .slice(8)
          .map((image, count) => (
            <Image
              key={count}
              fixed={image.node.childImageSharp.fixed}
              alt={`Partner image ${count}`}
            />
          ))}
    </Brand>
    <Body>
      <Cta to="/fundraising">{cta}</Cta>
    </Body>
  </Container>
)

export default Partners
