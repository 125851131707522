import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { useSwipeable } from "react-swipeable"

const Container = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  padding-bottom: 20px;
  @media only screen and (max-width: 32.5em) {
    width: 295px;
  }
`

const ImageWrapper = styled.span`
  width: 100%;
  flex: 0 0 auto;
  transition: all 0.3s;
  transform: translateX(calc(-100% * ${(props) => props.index}));
`

const Image = styled(Img)``

const Dots = styled.div`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #afafaf;
  font-size: 28px;
  @media only screen and (max-width: 32.5em) {
    font-size: 16px;
    bottom: -10px;
  }
`

const Dot = styled.span`
  margin: 0 7px;
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border: 2px solid #afafaf;
  border-radius: 50%;
  background: ${(props) => (props.filled ? "#afafaf" : "transparent")};
`

const Phone = () => {
  const [index, setIndex] = useState(0)
  const handlers = useSwipeable({
    onSwipedLeft: () => nextPic(),
    onSwipedRight: () => prevPic(),
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index !== 2) {
        setIndex((prev) => prev + 1)
      } else {
        setIndex(0)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [index])

  const nextPic = () => {
    if (index !== 2) {
      setIndex(index + 1)
    }
  }

  const prevPic = () => {
    if (index !== 0) {
      setIndex(index - 1)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query phoneQuery {
          allFile(filter: { relativeDirectory: { eq: "phone-recollect" } }, sort: {order: ASC, fields: name}) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 287, height: 587, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container {...handlers}>
          {data.allFile.edges.map((image, i) => (
            <ImageWrapper index={index} key={i}>
              <Image
                fixed={image.node.childImageSharp.fixed}
                objectFit="contain"
                loading="lazy"
              />
            </ImageWrapper>
          ))}
          <Dots>
            {data &&
              data.allFile.edges.map((_, count) => (
                <Dot
                  key={count}
                  onClick={() => setIndex(count)}
                  filled={count === index}
                />
              ))}
          </Dots>
        </Container>
      )}
    />
  )
}

export default Phone
