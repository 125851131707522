import React from "react"
import styled from "@emotion/styled"

import Card from "./Card"
import Cta from "./Cta"

const Container = styled.div`
  padding: 3rem 1.5rem;
  margin: 6rem auto;
  max-width: 130rem;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  padding: 3rem 3rem 9rem;
  @media only screen and (max-width: 32.5em) {
    grid-template-columns: 1fr;
    padding: 3rem 0;
  }
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78b526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
`

const Author = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Body = styled.p`
  color: #505050;
  padding-bottom: 1rem;
`

const Name = styled.span`
  color: #000;
`

const Section = styled.span`
  display: flex;
  align-items: center;
`

const Stars = styled.span`
  span {
    &::after {
      content: "\u2605";
      color: #8cc949;
      font-size: 2rem;
    }
  }
`

const Review = () => {
  // REPLACE STATIC CONTENT WITH API CALL
  const SEED_REVIEWS = [
    {
      author: "Jason D. | Google Play",
      body:
        "Was helpful, friendly service made it easy and profitable for me as I currently don't have transport. Service with a smile and information about the system progression. Thanks heaps.",
      url:
        "https://play.google.com/store/apps/details?id=au.com.returnit&reviewId=gp%3AAOqpTOHOawSbRv-ho8rAMaqdX2e6C-g-mnMzbXWD_3dR_L340RlXEPuSuPVztT3G7ehlZOzp6lC5rlHNFmOjrQ",
    },
    {
      author: "Mirela H. | Google Reviews",
      body:
        "Very good service, I booked, they came as they said, picked up the bags as they said. Thank you guys for the amazing job you are doing!",
      url: "https://goo.gl/maps/BECUNPnRdzeGGAcq8",
    },
    {
      author: "Trever J. | Google Reviews",
      body:
        "Super easy book a pick up via the app and its picked up and within a few days your account has the credit, every business and household should be doing this!",
      url: "https://goo.gl/maps/nyybSqyiciUF2jG98",
    },
    {
      author: "Dennis H. | Google Reviews",
      body: "Great service and friendly people, thank you.",
      url: "https://goo.gl/maps/d58PSXDXzH3MDLzZA",
    },
  ]

  return (
    <Container>
      <Primary>Customer Reviews</Primary>
      <Subtitle>Check out what people are saying about us!</Subtitle>
      <GridContainer>
        {SEED_REVIEWS.map(({ body, author, url }, count) => (
          <Card key={count}>
            <a href={url} style={{ textDecoration: "none" }}>
              <Content>
                <Body>{body}</Body>
                <Author>
                  <Section>
                    <Stars>
                      <span /> <span /> <span /> <span /> <span />
                    </Stars>
                  </Section>
                  <Name>{author}</Name>
                </Author>
              </Content>
            </a>
          </Card>
        ))}
      </GridContainer>
      <Cta href="https://app.re-collect.com.au">Get started</Cta>
    </Container>
  )
}

export default Review
