import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Cta from "../components/Cta"
import Charities from "../components/Charities"
import Steps from "../components/Steps"
import RecentBlogPosts from "../components/RecentBlogPosts"
import Benefits from "../components/Benefits"
import Fundraising from "../components/Fundraising"
import Stats from "../components/Stats"
import Ready from "../components/Ready"
import Review from "../components/Review"

export const IndexPageTemplate = ({
  title,
  subtitle,
  partnerImages,
  howItWorks,
  benefits,
  fundraising,
  statistics,
}) => {
  return (
    <>
      <Hero title={title} subtitle={subtitle} />
      <Charities images={partnerImages} />
      <div
        style={{
          width: "95%",
          height: "2px",
          margin: "0 auto",
          background:
            "linear-gradient(to right,rgba(0, 0, 0, 0) 0%,rgba(200, 200, 200, 0.8) 50%,rgba(0, 0, 0, 0) 100%)",
        }}
      />
      <Steps data={howItWorks}>
        <Cta href="https://app.re-collect.com.au/register">
          Get started for free
        </Cta>
      </Steps>
      <Benefits data={benefits} />
      <Fundraising data={fundraising} images={partnerImages} />
      <Stats data={statistics} />
      <Review />
      {/* <RecentBlogPosts /> */}
      <Ready svg>
        <Cta href="https://app.re-collect.com.au/register">Get Started</Cta>
        <Cta to="/contact" variant>
          Contact us
        </Cta>
      </Ready>
    </>
  )
}

/*
 */

const IndexPage = ({
  data: {
    allFile: { partnerImages },
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        seoDesc,
        howItWorks,
        benefits,
        fundraising,
        statistics,
      },
    },
  },
}) => (
  <Layout
    title="Recycling Pickup for Home, Business and Charity | ReCollect"
    seoDesc={seoDesc}
  >
    <IndexPageTemplate
      title={title}
      subtitle={subtitle}
      partnerImages={partnerImages}
      howItWorks={howItWorks}
      benefits={benefits}
      fundraising={fundraising}
      statistics={statistics}
    />
  </Layout>
)

export default IndexPage

export const PageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        howItWorks {
          title
          subtitle
          data {
            title
            image {
              childImageSharp {
                fixed(height: 136, width: 224) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            body
          }
        }
        benefits {
          title
          subtitle
          data {
            title
            body
            image {
              relativePath
            }
          }
        }
        fundraising {
          title
          subtitle
          cta
        }
        statistics {
          title
          subtitle
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "partners-recollect" } }) {
      partnerImages: edges {
        node {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
